.pageFourContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
}
.pageFourBackgroundImageBig {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: absolute;
    color: white;
    background-position: center;
    z-index: -1;
    background-position-x: 0;
    background-position-y: 70%;
}
.pageFourMinimizedBackgroundImg {
    display: none;
}
.pageFourSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.75vh;
    font-weight: bold;
    padding-top: 1.2vh;
    letter-spacing: .2vh;
    color: #002b49;
}
.pageFourMessage {
    color: #002b49;
    width: 24vw;
    float: right;
    margin-right: 5vw;
    margin-top: 4.5vh;
    z-index: 2;
}
.pageFourMessage h1{
    text-align: left;
    font-size: 2.2vw;
    letter-spacing: .110vw;
    font-family: sans-serif;
    margin-left: 3vw;
}
.pageFourMessage p {
    text-align: justify;
    font-size: .92vw;
    letter-spacing: .08vw;
    word-spacing: .32vw;
    font-family: sans-serif;
}
.pageFourSolarPanels {
    width: 21.2vw;
    height: 13vh;
    float: left;
    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 10% 100%);
    margin-left: 7vw;
    margin-top: 34.75vh;
}

.pageFourCoverPlate {
    z-index: 1;
    -webkit-clip-path: polygon(0 5%, 100% 0%, 100% 100%, 0% 96%);
    clip-path: polygon(0 5%, 100% 0%, 100% 100%, 0% 96%);
    width: 2vw;
    height: 10vh;
    margin-top: 41.5vh;
    margin-left: 0;
    position: absolute;
}
.coverPlateBoxShadow {
    margin-top: 6%;
    margin-left: .5%;
}
.pageFourMeter {
    width: 1.5vw;
    height: 4vh;
    margin-left: 26.75vw;
    margin-top: 46vh;
    border-radius: 45%;
    display: absolute;
}
.clickableButtons {
    display: inline-flex;
    width: 45vw;
    padding-left: 5.75vw;
    margin-top: 3vh;
}
.clickableButton {
    height: auto;
    width: 3.85vw;
    padding-right: 1vw;
}
.pageFourLightFixture {
    width: 1.75vw;
    height: 2vh;
    margin-left: 19.5vw;
    margin-top: 45vh;
    border-radius: 0 0 1.2rem 1.2rem;
    position: absolute;
}
.pageFourObjects {
    display: flex;
    margin-top: 29vh;
    margin-left: 8.5vw;
    position: absolute;
}
.clickableButtonText {
    padding-top: 5vh;
    width: 20vw;
    padding-left: 4vw;
}
@media (max-width: 1500px) {
    .pageFourBackgroundImageBig {
        height: 85vh;
    }
    .pageFourLightFixture {
        margin-top: 33vh;
    }
    .pageFourMeter {
        margin-top: 34vh;
    }
    .pageFourSolarPanels {
        margin-top: 37vh;
    }
    
    .pageFourCoverPlate {
        margin-top: 30vh;
    }
}
@media (max-width: 1250px) {
    .pageFourMinimizedBackgroundImgSmall {
        background-repeat: no-repeat;
        background-size:contain;
        width: 100vw;
        height: 65vh;
        margin-top: 30vh;
        z-index: -5;
        position:absolute;
    }
    .pageFourBackgroundImageBig {
        display: none;
    }
    .pageFourMessage {
        margin-right: 12vw;
        width: 82.5vw;
        margin-top: 0;
    }
    .pageFourMessage h1 {
        font-size: 3.25vh;
        float: left;
        width: 87.5vw;
        padding-left: 0;
    }
    .pageFourMessage p {
        font-size: 1.6vh;
        margin-left: 3vw;
        width: 82.5vw;
    }
    .clickableButtons {
        padding-left: 36vw;
        margin-top: 0;
    }
    .clickableButtonText {
        padding-left: 22vw;
        padding-top: 1vh;
    }
    .clickableButtonText p {
        width: 60vw;
        letter-spacing: .17vh;
        word-spacing: .45vh;
        font-size: 1.3vh;
    }
    .pageFourSolarPanels {
        height: 8.25vh;
        width: 35vw;
        padding-left: 0;
        position: absolute;
        margin-top: 50vh;
        margin-left: 17vw;
    }
    .pageFourCoverPlate {
        height: 6vh;
        width: 3.5vw;
        position: absolute;
        margin-top: 39vh;
        margin-left: 4vw;
    }
    .pageFourLightFixture {
        position: absolute;
        margin-left: 36vw;
        margin-top: 37.5vh;
        width: 3vw;
        height: 2vh;
    }
    .pageFourMeter {
        margin-left: 47vw;
        position: absolute;
        width: 4vw;
        height: 3vh;
        margin-top: 38.5vh;
    }
    .clickableButton {
        height: 8vh;
        width: 8vh;
        padding-right: 3vw;
    }
}
@media only screen and (max-width: 600px) {
    .pageFourSolarPanels {
        margin-top: 50vh;
        margin-left: 17vw;
    }
    .pageFourCoverPlate {
        margin-top: 39vh;
        margin-left: 4vw;
    }
    .pageFourLightFixture {
        margin-left: 36vw;
        margin-top: 39vh;
    }
    .pageFourMeter {
        margin-left: 47vw;
        margin-top: 39vh;
    }
    .clickableButtons {
        padding-left: 40vw;
    }
}

@media only screen and (max-width: 450px) {
    .pageFourMinimizedBackgroundImgSmall {
        width: 100vw;
        height: 80vh;
        margin-top: 40vh;
    }
    .pageFourMessage {
        margin-right: 12vw;
        width: 82.5vw;
        margin-top: 0;
    }
    .pageFourMessage h1 {
        font-size: 3.5vh;
        float: left;
        width: 87.5vw;
        padding-left: 0;
    }
    .pageFourMessage p {
        font-size: 1.75vh;
        margin-left: 3vw;
        width: 82.5vw;
    }
    .clickableButtons {
        padding-left: 20.5vw;
    }
    .clickableButtonText {
        padding-left: 12vw;
        padding-top: 0;
    }
    .clickableButtonText p {
        width: 70vw;
        letter-spacing: .15vh;
        word-spacing: .4vh;
        font-size: 1.5vh;
    }
    .pageFourSolarPanels {
        height: 6vh;
        width: 36vw;
        padding-left: 0;
        position: absolute;
        margin-top: 50.5vh;
        margin-left: 17vw;
    }
    .pageFourCoverPlate {
        height: 6vh;
        width: 3.5vw;
        position: absolute;
        margin-top: 34.75vh;
        margin-left: 4vw;
    }
    .pageFourLightFixture {
        position: absolute;
        margin-left: 36vw;
        margin-top: 34.5vh;
        width: 3vw;
        height: 2vh;
    }
    .pageFourMeter {
        margin-left: 47vw;
        position: absolute;
        width: 4vw;
        height: 3vh;
        margin-top: 34.5vh;
    }
    .clickableButton {
        height: 7vh;
        width: 7vh;
        padding-right: 4vw;
    }
}
