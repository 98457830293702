.navbarContainer {
    margin-bottom: 9vh;
    margin-top: 2vh;
    width: 100vw;
}

.navbar {
    list-style-type: none;
    display:flex;
    float: right;
    padding-bottom: 6vh;
    width: 100vw;
}
.iconContainer {
    padding-top: 2.5vh;
    margin-left: 5vw;
}

.iconContainer i {
    display: inline;
    margin-left: .65vw;
    margin-right: .65vw;
    font-size: 1.1vw;
}
.iconContainer i:hover {
    color: #ccff00;
}
.logos ul{
    display: inline-flex;
    padding-right: 19vw;
    width: 5vw;
    margin-left: 1.8vw;

}
.logos li {
    list-style-type: none;
    padding-top: 0;
}


.heart {
    width: 3.8vw;
    height: 6.25vh;
    padding-top: 1vh;
}

.logo {
    padding-top: 1.5vh;
    width: 8vw;
    height: 4.5vh;
    margin-right: 1.2vw;
    margin-left: .5vw;
}
.hat {
    width: 4.5vw;
    height: 5.1vh;
    padding-top: 1.5vh;
}
.hatContainer {
    display: flex;
    margin-left: 27.5vw;
}

.callUs {
    font-size: .8vw;
    font-family:sans-serif;
    padding-top: 2.5vh;
    font-weight: bold;
    width: 20vw;
}
.arrows {
    width: 4vw;
    height: 8vh;
    padding-right: .4vw;
    padding-left: .25vw;
}
.hamburgerBar {
    display: none;
}
a {
    color: inherit;
    text-decoration: none;
}
.callUs a {
    color: inherit;
    text-decoration: none;
}
@media only screen and (max-width: 1500px) {
    .hatContainer, .iconContainer {
        display: none;
    }
    .navbarContainer, .navbar {
        margin-left: 0;
        padding-left: 0rem;
    }
    .navbarContainer {
        padding-bottom: 1rem;
    }
    .iconContainer, .arrows, .iconContainer i, .callUs {
        padding-left: 0;
    }
    .arrows {
        width: 3.5rem;
        height: auto;
        padding-top: .75rem;
    }
    .logo {
        padding-top: 1.15rem;
        width: 6.75rem;
        height: 2.5rem;
    }
    .hamburgerBar {
        display: none;
    }
    .navbar {
        margin-right: 0vw;
    }
    .callUs {
        margin-left: 50vw;
        width: 40vw;
        padding-top: 1.5rem;
        font-size: 1.25rem;
    }
}
@media only screen and (max-width: 1250px) {
    .callUs {
        margin-left: 44vw;
        width: 40vw;
        padding-top: 1.5rem;
        font-size: 1.25rem;
    }
}
@media only screen and (max-width: 1024px) {
    .arrows {
        width: 4.5vh;
        height: auto;
        padding-top: .75rem;
        padding-right: 1vw;
    }
    .heart {
        width: 7vw;
        height: 5vh;
        padding-top: 1vh;
    }
    .logo {
        padding-top: 1.15rem;
        width: 8vh;
        height: 3.5vh;
    }
    .hamburgerBar {
        display: none;
    }
    .navbarContainer {
        margin-bottom: 11vh;
    }
    .navbar {
        margin-right: 0vw;
    }
    .callUs {
        margin-left: 38vw;
        width: 40vw;
        padding-top: 1.8vh;
        font-size: 1.5vh;
    }
    .logos ul {
        margin-left: 1vw;
    }

}
@media (max-width: 450px) {
    .arrows {
        width: 4.5vh;
        height: auto;
    }
    .heart {
        width: 9vw;
        height: auto;
        padding-top: 1.5vh;
    }
    .logo {
        padding-top: 2vh;
        width: 9vh;
        height: 3vh;
    }
    .hamburgerBar {
        display: none;
    }
    .navbarContainer {
        margin-bottom: 10vh;
    }
    .navbar {
        margin-right: 0vw;
    }
    .callUs {
        margin-left: 26vw;
        font-size: 1.5vh;
        padding-top: 2.5vh;
    }
    .logos ul {
        padding-left: 7.5vw;
    }
}