.pageTwoContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
}
.pageTwoContainer h1 {
    font-size: 2.45vw;
    color: black;
    line-height: 3vw;
    width: 30vw;
}
.pageTwoInfo {
    margin-left: 14vw;
    padding-top: 0;
    width: 30vw;
    position: absolute;
    margin-top: 8vh;
}

.pageTwoInfo p {
    font-size: 1.75rem;
    font-family: sans-serif;
    padding-bottom: 1%;
}
.pageTwoSideBar {
    float: left;
    margin-left: 4.5%;
    font-size: 1.1rem;
    letter-spacing: .1rem;
    margin-top: 4.5vh;
    font-weight: bold;
}
.pageTwoPictureSlideShowContainer {
    margin-left: 60vw;
    margin-top: 5vh;
}
.pageTwoButton button {
    background-color: transparent;
    color: white;
    border-radius: 2rem;
    border-color: darkgray;
    height: 4.1vh;
    width: 28.5vw;
    font-family: sans-serif;
    font-size: 1.45vw;
    letter-spacing:.2vw;
    font-weight: bold;
    
}
@media (max-width: 1500px) {
    .pageTwoInfo {
        margin-top: 5vh;
    }
}
@media only screen and (max-width: 1250px) {
    .pageTwoContainer h1 {
        font-size: 4.85vw;
        line-height: 4.85vw;
        margin-left: 10vw;
        width: 70vw;
    }
    .pageTwoPictureSlideShowContainer {
        position: absolute;
    }
    .pageTwoInfo {
        width: 100vw;
        z-index: 1;
        margin-left: 0;
    }
    .pageTwoInfo p {
        display: none;
    }

    .pageTwoPictureSlideShowContainer {
        margin-top: 30vh;
        margin-left: 7.5vw;
        height: 55vh;
    }
    .pageTwoButton button {
        font-size: 2.15vw;
        width: 59vw;
        margin-left: 10vw;
    }
}
@media only screen and (max-width: 450px) {
    .pageTwoPictureSlideShowContainer {
        margin-top: 20vh;
    }
    .pageTwoContainer h1 {
        width: 67vw;
        font-size: 5vw;
        line-height: 5vw;
        margin-left: 15vw;
    }
    .pageTwoButton button {
        font-size: 4vw;
        width: 67.5vw;
        margin-left: 15vw;
    }
}