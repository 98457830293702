.footer {
    float: left;
    font-size: 1.5rem;
    width: 100vw;
    font-family: 'Spartan', sans-serif;
    bottom: 0;
    margin-bottom: 2rem;
    margin-left: 5vw;
    margin-top: 1.5%;
    position: absolute;
}
.footer div {
    display: inline-flex;
}
.footer ul {
    display: inline-flex;
}
.footer li {
    list-style-type: none;
    margin-left: .45vw;
}
.PAIC {
    font-size: .85rem;
}
@media (max-width: 1250px){
    .PAIC {
        font-size: 1.25rem;
    }
}
@media (max-width: 800px){
    .PAIC {
        font-size: 1.25rem;
    }
}
@media (max-width: 600px){
    .PAIC {
        font-size: .85rem;
    }
    .footer {
        margin-bottom: 1rem;
    }
}
@media (max-width: 450px) {
    .footer {
        display: none;
    }
}