.pageNineBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: white;
    background-position: center;
    overflow: hidden;
}
.pageNineSidebar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.9vh;
    font-weight: bold;
    padding-top: 1.15vh;
    letter-spacing: .15vh;
}
.pageNineMessage {
    padding-left: 12vw;
}
.pageNineMessage h4 {
    font-size: 1.05vw;
    font-weight: bold;
    padding-top: .5vh;
}
.pageNineMessage h1 {
    font-size: 2.5vw;
    margin-bottom: 1.5vh;
}
.pageNineMessage span {
    color: #ccff00;
}
.pageNineMessage p {
    width: 30vw;
    text-align: justify;
    font-family: sans-serif;
    line-height: 1.25vw;
    margin-bottom: 3vh;
    font-size: .9vw;
}
.pageNineMessage button {
    width: 18vw;
    font-size: 1vw;
    border-radius: 1vw;
    color: #ccff00;
    background-color: transparent;
    border:white solid;
    border-width: .09vw;
    font-weight: bold;
    padding-top: .5vh;
    padding-bottom: .25vh;
    margin-bottom: 10vh;
    letter-spacing: .15vw;
}
.pageNineMessage button:hover {
    background-color: #ccff002d;
}
.pageNineButtons button {
    background-color: transparent;
    border: solid white .05vw;
    width: 2vw;
    height: 2vw;
    position: absolute;
}
.pageNineButtons button i {
    color: white;
    opacity: .5;
    font-size: 1.5vw;
}
.pageNineButtonsLeft button {
    background-color: transparent;
    border: solid white .05vw;
    width: 2vw;
    height: 2vw;
    margin-right: .5vw;
}
.pageNineButtonsLeft button i {
    color: white;
    opacity: .5;
    font-size: 1.5vw;
}
.pageNineButtonContainer {
    display: inline-flex;
    margin-left: 12vw;
    position: absolute;
    margin-top: 0;
}
.closeModal {
    border: none;
    background-color: white;
    color: black;
    font-size: 1.5vw;
}
.alternatingMessagesContainer {
    height: 25vh;
}
@media only screen and (max-width: 1250px) {
    .pageNineMessage button {
        width: 40vw;
        font-size: 2.5vw;
        margin-bottom: 11vh;
        border-radius: 2vw;
    }
    .pageNineMessage h1 {
        font-size: 6vw;
        width: 90vw;
    }
    .pageNineMessage p {
        width: 75vw;
        text-align: justify;
        font-family: sans-serif;
        line-height: 2vh;
        margin-bottom: 2vh;
        font-size: 1.9vh;
        letter-spacing: .05vh;
        margin-bottom: 1.9vh;
    }
    .pageNineMessage {
        padding-left: 10vw;
    }
    .pageNineButtons button {
        background-color: transparent;
        margin-right: 2vw;
        border: solid white;
        width: 5vh;
        height: 5vh;
    }
    .pageNineButtons button i {
        font-size: 3.75vh;
        padding-left: 0;
    }
    .pageNineButtonsLeft button {
        background-color: transparent;
        margin-right: 2vw;
        border: solid white;
        width: 5vh;
        height: 5vh;
    }
    .pageNineButtonsLeft button i {
        font-size: 3.75vh;
        padding-left: 0;
    }
    .pageNineMessage h4 {
        font-size: 1.75vw;
    }
    .closeModal {
        font-size: 2.5vh;
    }
}
@media (max-width: 450px) {
    .pageNineMessage button {
        width: 42.5vw;
        border-radius: 4vw;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        align-content: center;
    }
    .pageNineMessage button i {
        align-self: center;
    }
    .pageNineMessage h4 {
        font-size: 2.5vw;
    }
}