.sidebarMessage {
    writing-mode: vertical-lr; 
    transform: rotate(180deg);
    font-family: sans-serif;
    position: absolute;
}
@media (max-width: 1250px) {
    .sidebarMessage {
        display: none;
    }
}