.pageTenContainer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
}
.pageTenSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 2vh;
    font-weight: bold;
    padding-top: 1vh;
    letter-spacing: .15vh;
}
.pageTenMessage {
    margin-left: 12vw;
    color: black;
}
.pageTenMessage h1 {
    font-size: 3vw;
}
.pageTenMessage p{
    font-size: 1vw;
    font-weight: bold;
    font-family:sans-serif;
    padding-top: 2vh;
}
.pageTenInformation {
    padding-left: 8vw;
    display: flex;
}
.pageTenColumn {
    width: 23.5vw;
    display: flex;
    padding-left: 4.5vw;
    color: black;
    border-right: solid black .1rem;
}
.pageTenColumnThree {
    width: 23.5vw;
    display: flex;
    padding-left: 4.5%;
    color: #002b49;
}
.pageTenColumn p {
    font-size: 1vw;
}
.pageTenColumnTitle h3 {
    font-size: 1.9vw;
    margin-top: 1.25vw;
}
.pageTenColumnTitle {
    padding-right: 4vw;
    width: 4vw;
}
.pageTenColumnText {
    width: 11.5vw;
}
.pageTenColumnTextThree {
    width: 11.5vw;
}
.pageTenColumnText h4 {
    font-size: 1.1vw;
}
.pageTenColumnTextThree h4 {
    font-size: 1.1vw;
}
.pageTenColumnTextThree span {
    color: #38b6ff;
    font-size: 1.1vw;
    font-weight: bold;
}
.pageTenColumnText span {
    color: #38b6ff;
    font-size: 1.1vw;
    font-weight: bold;
}
.pageTenMapContainer {
    padding-left: 12vw;
}
.pageTenMap {
    width: 75vw;
}
@media only screen and (max-width: 1250px) {
    .pageTenMap {
        width: 85vw;
        height: 12.5vh;
    }
    .pageTenMapContainer {
        padding-left: 7vw;
    }
    .pageTenColumn {
        border-bottom: solid #002b49 .1rem;
        width: 80vw;
        border: none;
    }
    .pageTenInformation {
        display:block;
        margin-top: 2.15vh;
        padding-left: 3vw;
    }
    .pageTenColumnText {
        padding-top: 0;
        width: 60vw;
        padding-left: 15vw;
    }
    .pageTenColumnTitle {
        border-right: solid rgb(187, 187, 187) .15rem;
        padding-right: 0;
        width: 70vw;
    }
    .pageTenColumnThree {
        width: 100vw;
        border: none;
    }
    .pageTenMessage h1 {
        font-size: 3.5vh;
    }
    .pageTenColumnTitle h3 {
        font-size: 2.4vh;
        margin-top: 0;
    }
    .pageTenColumnText h4 {
        font-size: 2vh;
        margin-top: 0;
        margin-bottom: 2vh;
        padding-bottom: 0;
        width: 28.25vw;
    }
    .pageTenColumnText h4 span {
        font-size: 1.5vh;
        letter-spacing: .075vh;
        width: 32vw;
    }
    .pageTenMessage p{
        font-size: 1.4vh;
    }
    .pageTenColumnTextThree {
        padding-top: 0;
        width: 99.5vw;
        padding-left: 15vw;
    }
    .pageTenColumnTextThree h4 span {
        font-size: 1.5vh;
        letter-spacing: .075vh;
        width: 32vw;
    }
    .pageTenColumnTextThree h4 {
        font-size: 2vh;
        margin-top: 0;
        padding-bottom: 0;
        width: 27.5vw;
        margin-bottom: 2vh;
    }
    .pageTenCallUs {
        padding-top: 4vh;
    }
    .pageTenVisitUs {
        padding-top: 5vh;
    }
    .pageTenMessage {
        margin-left: 7.5vw;
    }
}
@media (max-width: 800px){
    .pageTenColumnTextThree {
        width: 100vw;
    }
}
@media (max-width: 450px) {
    .pageTenColumnText h4 {
        margin-bottom: 2vh;
        width: 41vw;
    }
    .pageTenColumnText h4 span{
        width: 41.5vw;
    }
    .pageTenColumnTextThree h4 {
        margin-bottom: 3.5vh;
        width: 45vw;
    }
    .pageTenColumnTextThree h4 span{
        width: 41.5vw;
    }
    .pageTenMessage h1 {
        margin-bottom: 2vh;
    }
    .pageTenMapContainer {
        margin-bottom: 2vh;
    }
    .pageTenColumnTextThree {
        width: 180vw;
    }
    .pageTenVisitUs {
        width: 20vw;
        margin-right: 0;
        padding-right: 0;
    }
}