.pageSevenBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: white;
    background-position: center;
    overflow: hidden;
}
.pageSevenSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.75vh;
    font-weight: bold;
    padding-top: 1.2vh;
    letter-spacing: .15vh;
}
.pageSevenText {
    padding-left: 11vw;
    width: 37.25vw;
    padding-top: 5vh;
}

.pageSevenMessage {
    display: flex;
}
.pageSevenMessage p{
    text-align: justify;
    font-family: sans-serif;
    line-height: 1.15vw;
    font-size: .9vw;
    letter-spacing: .15vw;
}
.pageSevenRightTitleMessage {
    padding-top: 12.5vh;
    margin-left: 22.5vw;
    width: 30vw;
}
.pageSevenRightTitleMessage span {
    color: #ccff00;
}
.pageSevenRightTitleMessage h1 {
    text-align: right;
    font-size: 2.65vw;
    line-height: 3vw;
    letter-spacing: .5vw;
}
.pageSevenLogoContainer {
    padding-left: 11vw;
    padding-top: 3vh;
}
.pageSevenLogo {
    width: 20vw;
}
@media (max-width: 1250px) {
    .pageSevenLogoContainer {
        padding-left: 17vw;
        padding-top: 3vh;
    }
    .pageSevenLogo {
        width: 60vw;
        margin-left: 4vw;
    }
    .pageSevenText {
        padding-top: 2vh;
        padding-left: 0;
        width: 80vw;
        margin-left: 10vw;
    }
    .pageSevenText p {
        line-height: 2.4vh;
        font-size: 1.8vh;
        letter-spacing: .15vh;
    }
    .pageSevenMessage {
        display: block;
    }
    .pageSevenRightTitleMessage h1 {
        text-align: right;
        font-size: 3.75vh;
        line-height: 3.75vh;
        letter-spacing: .3vh;
        width: 40vw;
        margin-left: 27.5vw;
        margin-top: 0;
    }
    .pageSevenRightTitleMessage {
        padding-top: 0;
    }
}
@media only screen and (max-width: 450px) {
    .pageSevenText p {
        line-height: 2.45vh;
        font-size: 1.75vh;
        letter-spacing: .175vh;
    }
    .pageSevenRightTitleMessage h1 {
        font-size: 3.25vh;
        line-height: 3.6vh;
        letter-spacing: .65vh;
        width: 55vw;
        margin-left: 14vw;
        margin-top: 0;
    }.pageSevenLogo {
        width: 60vw;
        margin-left: 5vw;
    }
}