.pageControlContainer {
    float: right;
    margin-right: 5.1vw;
    margin-top: 0;
}
.pageControlContainer ul {
    padding-top: 2.4vh;
    padding-bottom: .4vh;
}
.pageControlContainer li {
    font-size: 2.5vh;
    line-height: 2.5vh;
}
.pageControlContainer i {
    float: right;
    margin-right: .5vw;
    font-size: 2.5vh;
}
@media (max-width: 1250px) {
    .pageControlContainer {
        display: none;
    }
}
@media (max-width: 450px) {
    .pageControlContainer {
        margin-right: 1.25vw;
        margin-top: 0%;
        display: none;
    }
    .pageControlContainer li{
        font-size: 1.5rem;
        line-height: 1rem;
    }
    .pageControlContainer i {
        font-size: 1.5rem;
    }
    .pageControlContainer {
        margin-top: 0;
    }
    .pageControlContainer ul {
        padding-top: 25%;
        padding-bottom: 0;
        margin-bottom: 1vh;
    }
} 