.pageSixContainer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
}
.pageSixSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.9vh;
    font-weight: bold;
    margin-top: 1.2vh;
    letter-spacing: .15vh;
}

.pageSixCardContainerBig {
    display: flex;
    height: 10vh;
    margin-left: 12.5vw;
}
.pageSixFlipBookContainerSmall {
    display: none;
}

.pageSixCard {
      position:absolute;
      box-shadow: 0 0 1rem black;
}

.pageSixMessage {
    margin-left: 12.2vw;
    margin-bottom: 8vh;
    color: black;
    font-weight: bold;
}
.pageSixMessage p {
    padding-top: .5vh;
    font-size: .9vw;
}
.pageSixMessage h1 {
    line-height: 3.25vw;
    font-size: 2.7vw;
}
.pageSixMessage span {
    color: #38b6ff;
}
.iconContainer {
    display: inline-flex;
    margin-left: 25vw;
    margin-top: 1vh;
}
.iconContainer i {
    color: #38b6ff;
}
@media only screen and (max-width: 1250px){
    .pageSixMessage {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .pageSixFlipBookContainerSmall {
        margin-top: 5vh;
        padding-top: 0;
        height: 0;
        margin-left: 0vw;
        display: grid;
        margin-right: 7vw;
    }
    .pageSixCardContainerBig {
        display: none;
    }
    .pageSixMessage h1 {
        line-height: 4.2vw;
        font-size: 4.8vw;
        margin-bottom: 0;
    }
    .pageSixMessage p {
        padding-top: .5vh;
        font-size: 2vw;
    }
    .pageSixMessage {
        margin-left: 10vw;
    }
    .iconContainer div {
        padding-right: 5vw;
        margin-top: 0vh;
    }
    .iconContainer {
        margin-left: 25vw;
    }
    .iconContainer div i {
        font-size: 3vh;
    }
}
@media (max-width: 1000px) {
    .iconContainer {
        margin-top: 6vh;
    }
    
}
@media (max-width: 850px) {
    .iconContainer {
        margin-top: 0;
    }
}
@media (max-width: 600px) {
    .pageSixFlipBookContainerSmall {
        margin-left: 2vw;
    }
    .iconContainer {
        margin-left: 25vw;
        margin-top: 5vh;
    }
}
@media only screen and (max-width: 450px){
    .pageSixMessage {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .pageSixMessage h1 {
        line-height: 4.2vw;
        font-size: 6vw;
        margin-bottom: 0;
    }
    .pageSixFlipBookContainerSmall {
        margin-top: 2vh;
        padding-top: 0;
        height: 23rem;
        margin-right: 0;
       
    }
    .pageSixMessage p {
        font-size: 3.5vw;
    }
    .iconContainer {
        margin-left: 22vw;
        margin-top: 5vh;
    }
}
@media (max-width: 400px) {
    .pageSixFlipBookContainerSmall {
        margin-left: 10vw;
    }
    .iconContainer  {
        margin-left: 17vw;
        margin-top: 5vh;
    }
    .iconContainer div {
        padding-right: 5vw;
    }
    .iconContainer div i {
        font-size: 3vh;
    }
}
