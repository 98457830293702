.cardContainer {
    height: 15vw;
    width: 15vw;
    text-align: center;
    padding-top: 3.5vh;
    padding-left: 1.5vw;
}
.cardContainerImage{
    height: 8vw;
    width: 8vw;
    position: absolute;
}

.cardContainerImage img{
    object-fit:cover;
    position: center;
    width: 13.5vw;
    height: 13.5vw;
    border-radius: 1vw;
}
.doubleTapIcon img {
    display: none;
}

@media (max-width: 1250px){
    .cardContainer {
        height: 49vh;
        width: 67vw;
        box-shadow: 0 0 1rem 0 black;
        padding-left: 1.25vw;
        padding-top: 1vh;
        padding-bottom: 0;
        margin-top: 0;
        display: flex;
    }
    .cardContainerImage img {
        width: 55vw;
        height: 42vh;
        box-shadow: 0 0 .2rem 0 black;
        margin-top: 3.5vh;
        margin-left: 5.5vw;
    }
    .cardContainerImage {
        position: relative;
    }
    .clickHereIcon {
        display: none;
    }
    .doubleTapIcon img {
        width: 20vw;
        height: 2.75vh;
        padding-top: .1vh;
        margin-left: 45vw;
        position: absolute;
        display: block;
    }
}

@media only screen and (max-width: 450px){
    .cardContainer {
        height: 45vh;
        width: 80vw;
        box-shadow: 0 0 1rem 0 black;
        padding-left: 1.25vw;
        padding-top: 1vh;
        padding-bottom: 0;
        margin-top: 0;
        display: flex;
    }
    .cardContainerImage img {
        width: 68vw;
        height: 37vh;
        box-shadow: 0 0 .2rem 0 black;
        margin-top: 3.5vh;
        margin-left: 5.5vw;
    }
    
    .doubleTapIcon img {
        margin-left: 55vw;
        position: absolute;
        display: block;
    }

    .clickHereIcon {
        display: none;
    }
}