.pageElevenContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
}
.pageElevenSelectorImageContainer {
    width: 25vw;
    height: fit-content;
}
.pageElevenSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.75vh;
    font-weight: bold;
    margin-top: 1.1vh;
    letter-spacing: .15vh;
}
.pageElevenSelectorImageContainer img {
    position: center;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.pageElevenSubMessage {
    width: 33vw;
    margin-top: auto;
}
.pageElevenSubMessage p {
    font-size: 1vw;
}
.pageElevenMessage h1 {
    font-size: 2.5vw;
    width: 20vw;
    margin-bottom: 0;
    padding-bottom: .75vh;
    margin-top: 3.5vh;
}
.pageElevenMessage h1 span {
    color: #38b6ff;
}
.pageElevenMessage {
    margin-left: 9vw;
    font-size: .82vw;
}

.pageElevenSelectorContainer {
    display: inline-flex;
    margin-left: 8vw;
    margin-top: 2.2vh;
}
.pageElevenSelectorDetails {
    display: inline-flex;
    color: black;
    padding-left: .55vw;
}
.pageElevenSelectorDetails p {
    padding-right: .2vw;
    padding-left: .2vw;
    font-size: .8vw;
}
.pageElevenSelector h4 {
    padding-left: .8vw;
    font-size: 1.25vw;
    word-spacing: .2vw;
    margin-bottom: 0;
}
.pageElevenSelectorDetailsTime {
    border-right: solid black .1rem;
}
.pageElevenSelector {
    margin-left: 1.25vw;
    margin-right: 1.25vw;
}
.pageElevenSelectorDetails button {
    width: 2.9vw;
    height: 2.5vh;
    margin-top: 1.6vh;
    margin-left: .2vw;
    background-color: #38b6ff;
    color: white;
    font-family: sans-serif;
    border: none;
    border-radius: .2vw;
    font-weight: bolder;
    font-size: .65vw;
    letter-spacing: .105vw;
}
.pageElevenSchedule {
    display: inline-flex;
}
.closeModal {
    color: black;
    border: none;
    background-color: white;
    font-size: 1vw;
}
@media (max-width: 1250px) {
    .pageElevenMessage h1 {
        font-size: 3.25vh;
        width: 100%;
        margin-top: 0;
    }
    .pageElevenSubMessage {
        width: 80vw;
        text-align: justify;
        font-size: 1.75vh;
        margin-top: 0;
    }
    .pageElevenSelectorImageContainer {
        width: fit-content;
        height: 16vh;
    }
    .pageElevenSelectorContainer {
        display: block;
        margin-left: 7.5vw;
    }
    .pageElevenSelector h4 {
        padding-left: 1.1vh;
        font-size: 1.75vh;
        word-spacing: .125vh;
        margin-bottom: 0;
        width: 40vw;
    }
    .pageElevenSelectorDetails {
        display: inline-flex;
        color: black;
        padding-left: .8vh;
    }
    .pageElevenSelector p {
        font-size: 1.55vh;
    }
    .pageElevenSelector {
        display: inline-flex;
    }
    .pageElevenSelectorDetails button {
        width: 8vw;
        height: 3vh;
        margin-top: 1.1vh;
        margin-left: .6vw;
        font-weight: bolder;
        font-size: 1.3vh;
        border-radius: .6vh;
    }
    .pageElevenSubTitle {
        font-size: 2.25vw;
    }
    .pageElevenSchedule {
        display: block;
    }
    .closeModal {
        font-size: 2.5vh;
    }
    .pageElevenSelectorDetailsTime {
        margin-right: 1vw;
        width: 5vw;
    }
}
@media (max-width: 450px) {
    .pageElevenMessage h1 {
        font-size: 2.75vh;
        width: 100%;
        margin-top: 0;
    }
    .pageElevenSelectorImageContainer {
        width: 47.5vw;
    }
    .pageElevenSelectorDetails button {
        width: 12vw;
    }
    .pageElevenSubTitle {
        font-size: 3vw;
    }
    .pageElevenSelectorDetailsTime {
        margin-right: 1vw;
        width: 14vw;
    }
}