.pageFiveBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: white;
    background-position: center;
    overflow: hidden;
}
.pageFiveSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.8vh;
    font-weight: bold;
    padding-top: 1.25vh;
    letter-spacing: .1vh;
}
.pageFiveIconBig {
    padding-top: .5vh;
    padding-left: 10vw;
    font-weight: bold;
    font-size: 5.2vh;
    line-height: 4.8vh;
    color: #ccff00;
}

.pageFiveAlternatingMessages {
    width: 27vw;
    font-family: sans-serif;
    line-height: 2.5vh;
    padding-top: .5vh;
    font-size: .975vw;
    height: 35vh;
    position: absolute;
    margin-left: 65vw;
}

.pageFiveAlternatingMessages p {
    text-align: justify;
    width: 97%;
}
.pageFiveAlternatingMessages h3 {
    margin-top: 7vh;
    font-size: 1.1vw;
}
.pageFiveAlternatingMessages h4 {
    font-weight: lighter;
    font-size: .975vw;
}
.pageFiveButtons {
    display: inline-flex;
}
.pageFiveButtonOne {
    display: inline-flex;
    padding-right: 3.5vw; 
}
.pageFiveMessageTitle span {
    color: #ccff00;
}
.pageFiveMessageTitle {
    margin-top: 25vh;
    margin-left: 10vw;
    font-size: 1.3vw;
    float: left;
    width: 35vw;
}
.pageFiveButtonsContainer button {
    height: 2.5vw;
    width: 2.35vw;
    background-color: transparent;
    color: white;
    border: solid white;
    position: absolute;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.pageFiveButtonsContainer button i {
    font-size: 2.5vh;
}
.pageFiveButtonsContainer {
    float: right;
    margin-top: 32vh;
    margin-right: 24vw;
}
.pageFiveIconSmall {
    display: none;
}
.pageFiveAlternatingMessageSmall{
    display: none;
}
@media (max-width: 1700px){
    .pageFiveButtonsContainer {
        margin-right: 23vw;
    }
}
@media (max-width: 1250px){
    .pageFiveButtonsContainer {
        margin-right: 22vw;
    }
}
@media only screen and (max-width: 1250px) {
    .pageFiveIconBig {
        font-size: 3vh;
        padding-bottom: 0;
        margin-bottom: 0;
        float: right;
        margin-right: 10vw;
        height: 1.25vh;
        margin-top: 1.25vh;
    }
    .pageFiveMessageTitle h1 {
        font-size: 4vw;
        text-align: right;
        margin-top: 3vh;
        width: 60vw;
    }
    .pageFiveMessageTitle {
        padding-top: 0;
        margin-top: 3vh;
        margin-left: 26vw;
    }
    .pageFiveAlternatingMessageBig {
        display: none;
    }
    .pageFiveAlternatingMessageSmall {
        display: grid;
    }

    .pageFiveAlternatingMessages {
        margin-top: 35vh;
        width: 60vw;
        margin-left: 32vw;
        position: absolute;
    }
    .pageFiveAlternatingMessages p {
        font-size: 2.25vw;
    }
    .pageFiveAlternatingMessages h3 {
        font-size: 3vw;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 1vh;
        float: right;
        text-align: right;
        margin-right: 2vw;
    }
    .pageFiveAlternatingMessages h4 {
        font-size: 2.5vw;
        float: right;
        margin-top: .9vh;
        text-align: right;
        margin-right: 2vw;
        height: 1vh;
    }
    .pageFiveButtonsContainer {
        margin-top: 56vh;
        margin-right: 16.5vw;
        float: right;
    }
    .pageFiveButtonsContainer button {
        color: #ccff00;
        height: 5vh;
        width: 5vh;
        font-size: 1.6rem;
    }
    .pageFiveButtonOne {
        padding-right: 10vw;
    }
}
@media only screen and (max-width: 450px) {
    .pageFiveIconSmall {
        margin-top: 16vh;
        margin-left: 87vw;
        display:grid;
        color: #ccff00;
    }
    .pageFiveIconBig {
        display: none;
    }
    .pageFiveMessageTitle h1 {
        font-size: 5.25vw;
        text-align: right;
        margin-top: 0;
        padding-top: 0;
        margin-right: 0;
        width: 65vw;
        margin-left: 6vw;
    }
    .pageFiveMessageTitle {
        margin-left: 20vw;
    }
    .pageFiveAlternatingMessages {
        margin-top: 32vh;
        width: 70vw;
        margin-left: 20vw;
    }
    .pageFiveAlternatingMessages p{
        font-size: 3vw;
        line-height: 4.25vw;
        width: 67vw;
    }
    .pageFiveAlternatingMessages h3 {
        font-size: 3.75vw;
        width: 67vw;
    }
    .pageFiveAlternatingMessages h4 {
        font-size: 3.25vw;
        width: 67vw;
    }
    .pageFiveButtonsContainer {
        margin-top: 55vh;
        margin-right: 22vw;
    }
    .pageFiveButtonsContainer button {
        height: 5vh;
        width: 5vh;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        align-content: center;
    }
    .pageFiveButtonsContainer div button i {
        font-size: 3.75vh;
        padding-top: 0;
        padding-bottom:0;
        margin-top: 0;
        margin-bottom: 0;
        align-self: center;
    }
    .pageFiveButtonOne {
        margin-right: 3vw;
    }
}

