.pageOneBackground {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: white;
    background-position: center;
    overflow: hidden;
}

.backgroundInfo {
    margin-left: 14vw;
    margin-right: 2.5vw;
    padding-bottom: 2.5vh;
}

.pageOneBackground span {
    color: #ccff00;
}
.pageOneBackground h1 {
    font-size: 5vw;
    line-height: 5vw;
    margin-top: 12.5vh;
    margin-bottom: 2.5vh;
    width: 25vw;
}
.backgroundInfo p {
    font-size: 1vw;
    line-height: .9vw;
}

.pageOneBackground button {
    background-color: transparent;
    color: white;
    border-radius: 2rem;
    border-color: white;
    height: 4.1vh;
    width: 21.5vw;
    margin-left: .5vw;
    font-family: sans-serif;
    font-size: 1.25vw;
    letter-spacing:.2vw;
    font-weight: bold;
}
.pageOneBackground button:hover {
    background-color: #ccff0021;
}
.pageOneSideBar {
    padding-left: 4.7vw;
    float: none;
    letter-spacing: .1vh;
    margin-top: 24vh;
}
.backgroundInfoIpad {
    display: none;
}
@media (max-width: 1250px)  {
    .backgroundInfoIpad {
        display: grid;
    }
    .backgroundInfo {
        display: none;
    }
    .backgroundInfoParagraphIpad h1 {
        line-height: 10.25vw;
        font-size: 10vw;
        margin-top: 0rem;
        padding-top: 0;
        margin-bottom: 3vh;
        margin-left: 4vw;
        width: 55vw;
    }
    .backgroundInfoParagraphIpad button {
        font-size: 2vw;
        width: 35vw;
        margin-left: 4vw;
    }
    .backgroundInfoPIpad p {
        font-size: 2.4vw;
        width: 40vw;
        letter-spacing: .15vw;
        line-height: 2.4vw;
    }
    .backgroundInfoParagraphIpad {
        margin-top: 0;
        padding-top: 2.5vh;
    }
    .backgroundInfoPIpad {
        margin-left: 15vw;
        margin-top: 0;
        padding-top: 0;
    }
    .backgroundInfoParagraphPremierIpad {
        padding-top: 2vh;
    }
    .backgroundInfoPIpad {
        margin-top: 20vh;
        margin-left: 4vw;
    }
    .pageOneSideBar {
        float: left;
        margin-left: 0;
        font-size: 1.75vh;
        letter-spacing: .15vh;
        margin-top: 6vh;
    }
}
@media only screen and (max-width: 450px){
    .backgroundInfoParagraphIpad h1 {
        line-height: 6.5vh;
        font-size: 6.5vh;
        width: 70vw;
    }
    .backgroundInfoParagraphIpad button {
        width: 40vw;
    }
    .backgroundInfoPIpad p {
        font-size: 2.2vh;
        width: 55vw;
        letter-spacing: .15vh;
        line-height: 2.4vh;
    }
}