.pageTwelveBackground {
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
}
.pageTwelveSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 2vh;
    font-weight: bold;
    padding-top: 1vh;
    letter-spacing: .15vh;
}
.pageTwelveMessage h1 {
    font-size: 2.45vw;
    color: black;
    line-height: 3vw;
    margin-left: 14vw;
}
.blogContainer {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    z-index: 5;
}
