.pageThreeBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: white;
    background-position: center;
    overflow: hidden;
}

.pageThreeSideBar {
    float: left;
    margin-left: 4.5vw;
    font-size: 1.9vh;
    font-weight: bold;
    padding-top: 1vh;
    letter-spacing: .15vh;
}

.pageThreeMessage {
    padding-left: 10vw;
    font-weight: bold;
    font-size: 2.9vw;
    padding-bottom: 3.5vh;
    line-height: 2.8vw;
}
.pageThreeMessage span {
    color: #ccff00;
}
.pageThreeTextBoxContainer {
    padding-left: 9.5vw;
    width: 83vw;
    height: 40vh;
}
.pageThreeSlideShowControlContainer {
    float: right;
    margin-right: 10vw;
}
.pageThreeSlideShowControl {
    display: flex;
}
.pageThreeSlideShowControl div {
    margin-right: 3vw;
    margin-bottom: 2vw;
}
.pageThreeSlideShowControl button {
    height: 4vh;
    width: 4vh;
    background-color: transparent;
    border: white solid;
    position:absolute;
    padding-left: 0;
    padding-right: 0;
}
.pageThreeSlideShowControl button i {
    color: gray;
    font-size: 3vh;
}
.pageThreeTextBoxContainer p {
    font-size: 1.05vw;
}
.pageThreeButton button {
    background-color: transparent;
    color: white;
    border-radius: 2rem;
    border-color: white;
    height: 4.1vh;
    width: 21.5vw;
    margin-left: 10vw;
    font-family: sans-serif;
    font-size: 1.15vw;
    letter-spacing:.2vw;
    position: absolute;
    font-weight: bold;
}
.pageThreeButton button:hover {
    background-color: #ccff0021;
}
@media (max-width: 1500px) {
    .pageThreeTextBox h1 {
        font-weight: lighter;
        padding-top: 1.75vh;
        margin-bottom: 1.75vh;
        line-height: 2.4vh;
        font-size: 2.25vh;
    }
}
@media only screen and (max-width: 1250px){
    .pageThreeBackgroundImage {
        height: 100vh;
        width: 100vw;
    }
    .pageThreeTextBoxContainer {
        margin-left: 0;
        padding-left: 8vw;
        height: 40vh;
        margin-top: 0;
        width: 88vw;
    }
    .pageThreeSlideShowControlContainer {
        margin-top: 7vh;
        margin-left: 25vw;
    }
    .pageThreeSlideShowControl {
        margin-left: 50vw;
    }
    .pageThreeSlideShowControl div {
        margin-right: 10vw;
    }
    .pageThreeSlideShowControlContainer p {
        margin-top: 2vh;
        font-size: .75vh;
        margin-left: 30vw;
        display: none;
    }
    .pageThreeMessage {
        font-size: 3.75vh;
        line-height: 5vw;
        margin-top: 12.5vh;
        padding-bottom: 0;
    }
    .pageThreeSlideShowControl button {
        height: 5vh;
        width: 5vh;
        position:absolute;
    }
    .pageThreeSlideShowControl button i {
        color: #ccff00;
        font-size: 3vh;
    }
    .pageThreeTextBox span {
        color: white;
        font-weight: lighter;
    }
    .pageThreeTextBox h1 {
        font-weight: lighter;
        padding-top: 1.75vh;
        margin-bottom: 1.75vh;
        line-height: 2.4vh;
        font-size: 2.25vh;
    }
    .pageThreeTextBox p {
        font-size: 1.59vh;
    }
    .pageThreeButton button {
        font-size: 2vw;
        width: 35vw;
        margin-left: 33vw;
    }
}
@media only screen and (max-width: 800px){
    .pageThreeTextBoxContainer {
        margin-left: 0;
        padding-left: 8%;
        height: 40vh;
        margin-top: 2.5%;
    }
    .pageThreeMessage {
        font-size: 2.5rem;
        line-height: 2.5rem;
        margin-top: 25%;
    }
    .pageThreeSlideShowControlContainer {
        margin-top: 1vh;
    }
    .pageThreeButton button {
        font-size: 2.2vw;
    }
}

@media (max-width: 600px) {
    .pageThreeTextBox h1 {
        line-height: 8vw;
        font-size: 5.5vw;
        margin-bottom: 0;
    }
    .pageThreeTextBoxContainer p {
        font-size: 2vh;
    }
    .pageThreeTextBoxContainer {
        margin-top: 0;
    }
    .pageThreeMessage {
        font-size: 4vh;
        line-height: 4vh;
    }
    .pageThreeSlideShowControlContainer {
        margin-top: 5vh;
    }
    .pageThreeButton button {
        margin-top: 5vh;
    }
}
@media (max-width: 450px) {
    .pageThreeSlideShowControlContainer {
        margin-top: 8vh;
        margin-left: 21vw;
    }

    .pageThreeMessage {
        padding-bottom: 0;
    }
    .pageThreeButton button {
        margin-top: 8vh;
        margin-left: 30vw;
        font-size: 2.25vw;
    }
}