.textBox {
    width: 24vw;
    padding-left: .75vw;
    padding-right: .75vw;
    height: 30vh;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(15px);
    background-color: rgba(255,255,255,.15);
    border-radius: 2vh;
}
.textBoxContainer {
    margin-right: .35vw;
    margin-left: .35vw;
}

.textBox span{
    color: #ccff00;
}

.textBox p {
    text-align: justify;
    text-justify: auto;
}
.textBox h1 {
    font-size: 1.65vw;
    text-align: center;
    padding-right: .5vw;
}
@media only screen and (max-width: 1250px) {
    .textBox {
        height: 42.5vh;
        margin-top: 0;
        width: 24vw;
        padding-left: 2vw;
        padding-right: 2vw;
    }
    .textBox h1 {
        margin-bottom: 0;

    }
    .textBox p {
        padding-top: 0;
        margin-top: 0;
        padding-left: 1.25vw;
        padding-right: 1.25vw;
    }
    .textBoxContainer {
        margin-right: .7vw;
    }
}
@media only screen and (max-width: 1000px) {
    .textBox {
        height: 36vh;
        width: 40vw;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .textBox h1 {
        font-size: 1.16rem;
        margin-bottom: 0;

    }
    .textBox p {
        font-size: 1.1rem;
        padding-top: 1.5%;
        margin-top: 0;
        padding-left: 3.5%;
        padding-right: 3.5%;
    }
}
@media only screen and (max-width: 600px) {
    .textBox {
        height: 40vh;
        width: 80vw;
    }
    .textBox h1 {
        font-size: 1.16rem;
        margin-bottom: 0;

    }
    .textBox p {
        font-size: .65rem;
        padding-top: 1.5%;
        margin-top: 0;
        padding-left: 3.5%;
        padding-right: 3.5%;
    }
}
@media only screen and (max-width: 450px) {
    .textBox {
        height: 45vh;
        width: 80vw;
    }
}