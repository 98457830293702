.pageEightBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    position: fixed;
    color: black;
    background-position: center;
    overflow: hidden;
}
.pageEightSideBar {
    float: left;
    margin-left: 4.4vw;
    font-size: 1.9vh;
    font-weight: bold;
    padding-top: 1.5vh;
    letter-spacing: .15vh;
}
.pageEightMessage {
    padding-left: 12vw;
}
.pageEightMessage h1 {
    font-size: 2.725vw;
    line-height: 2.85vw;
}
.pageEightText {
    width: 22vw;
}
.pageEightText {
    text-align: justify;
    padding-top: 4vh;
    line-height: 1.25vw;
    font-family: sans-serif;
    font-size: 1vw;
}
.pageEightMessage button {
    width: 13.5vw;
    font-size: .9vw;
    border-radius: 1rem;
    color: #38b6ff;
    background-color: white;
    border-color: black;
    margin-top: 3.5vh;
    font-weight: bold;
    border-width: .2vw;
    letter-spacing: .1vw;
}
.pageEightMessage button:hover {
    background-color: rgba(128, 128, 128, 0.288);
}
.pageEightMessage span {
    color: #38b6ff;
}
.pageEightSubTitle {
    font-size: .8vw;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: .1vw;
}
.closeModal {
    color: black;
    font-size: 1vw;
    background-color: white;
    border: none;
}
@media only screen and (max-width: 1250px) {
    .pageEightText {
        width: 37vw;
    }
    .pageEightMessage h1 {
        font-size: 3.5vh;
        line-height: 3.7vh;
    }
    .pageEightBackgroundImage {
        position: relative;
        background-position-x: 36%;
    }
    .pageEightSubTitle {
        font-size: 1.5vh;
    }
    .pageEightText {
        padding-top: 2vh;
        line-height: 2.5vh;
        font-size: 2.15vh;
        word-spacing: .025vh;
    }
    .pageEightMessage {
        padding-left: 8vw;
        padding-top: 3vh;
    }
    .pageEightMessage button {
        width: 35vw;
        font-size: 2.5vw;
        border-radius: 2vw;
        color: #38b6ff;
        background-color: white;
        border-color: solid black;
        margin-top: 4vh;
        font-weight: bold;
        border-width: .35vw;
        letter-spacing: .2vw;
    }
    .closeModal {
        font-size: 2.75vh;
    }
}
@media (max-width: 450px) {
    .pageEightMessage h1 {
        font-size: 3vh;
        line-height: 3.25vh;
    }
    .pageEightText {
        padding-top: 1.5vh;
    }
}