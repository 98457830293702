.pictureSlideShowContainer img {
    height: 58vh;
    width: 30vw;
    object-fit: cover;
    box-shadow: 10px 8px 18px black;
}
.pictureSlideShowContainer p{
    font-size: .8rem;
    font-family: sans-serif;
    font-weight: bolder;
    color: #002b49;
}
.pictureSlideShowContainer button {
    height: 3.5vh;
    width: 16vw;
    border-radius: 1rem;
    background-color: white;
    border: .20rem solid black;
    color: #38b6ff;
    float: right;
    margin-right: 12vw;
    font-weight: bold;
    margin-top: 2vh;
}
.pictureSlideShowContainer button:hover {
    background-color: #022b493b;
}
.pictureSlideShowPictures {
    display: flex;
}
.pictureSlideShowCaption {
    margin-right: 5vw;
}
@media only screen and (max-width: 1250px) {
    .localInstalls {
        display: none;
    }
    .pictureSlideShowContainer img {
        height:42.5vh;
        width: 66vw;
        z-index: -1;
    }
    .pictureSlideShowContainer{
        padding-bottom: .5vh;
        margin-left: 0;
    }
    .pictureSlideShowContainer button {
        margin-right: 0;
        margin-top: 2vh;
        margin-left: 3vw;
        width: 53vw;
        height: 4.5vw;
        font-size: 2.5vw;
        letter-spacing: .15vw;
        border-radius: 2.5vw;
    }
    .pictureSlideShowCaption p {
        text-align: center;
    }
}

@media (max-width: 450px) {
    .pictureSlideShowContainer img {
        height:42.5vh;
        width: 84vw;
        z-index: -1;
    }
    .pictureSlideShowContainer{
        margin-top: 4vh;
    }
    .pictureSlideShowContainer button {
        width: 60vw;
        height: 7vw;
        font-size: 2.5vw;
        letter-spacing: .15vw;
        border-radius: 3.5vw;
        margin-left: 0;
        margin-right: 6vw;
        margin-top: 2vh;
    }
}